<template>
  <!--CForm @submit.prevent="onSubmit" @change="onChange"-->
  <div @change="onChange">
    <ACheckBox label="Generate" :placeholder="`Generar interfaz de ${entity.name}.`" v-model="state.status_id" />
    <AMultiSelect ref="connection_id" :label="`IC ${entity.name}`" v-model="state.connection_id" :options="list" />
    <AInput :name="validation[0] + 'consecutive'" :label="`Comprobante ${entity.name}`" v-model="state.consecutive" a-isValid="isValid('consecutive')" a-errors="getErrors('consecutive')" />
    <ACheckBox v-if="entity.sfv" label="Include credit balances" :placeholder="`incluir derrame de saldos a favor en la interfaz de ${entity.name}.`" v-model="state.include_sfv" />
  </div>
  <!--/CForm-->
</template>

<script>
export default {
  name: 'PropertyConnection',
  props: {
    connection: {
      type: Object,
      required: true
    },
    entity: {
      type: Object,
      required: true
    },
    validation: {
      type: Array,
      default: () => ['', '']
    }
  },
  watch: {
    connection(val) {
      this.state = val
    }
  },
  data() {
    return {
      list: this.entity.__list,
      state: { ...this.connection }
    }
  },
  mounted() {
    this.list = this.entity.__list
    this.state.connection_id = ''
    this.$nextTick(() => {
      this.state = { ...this.state, ...this.connection }
      this.onChange()
    })
  },
  methods: {
    onSubmit(e) {
      //console.log('SUBMIT')
    },
    onChange(e) {
      this.$emit('connectionChanged', this.state, e)
    },
    isValid(field) {
      if (!this.validation[1]) return true
      return this.validation[1](this.validation[0] + field)
    },
    getErrors(field) {
      if (!this.validation[2]) return []
      return this.validation[2](this.validation[0] + field)
    }
  }
}
</script>
