export default {
  methods: {
    cropImage(origin, pivot_type, options) {
      const self = this
      const media = self.images[origin][pivot_type]
      if (media?.url) {
        self.cropper.type = pivot_type
        self.cropper.modal = true
        self.cropper.media = media
        self.cropper.options = options || {} //, cropend, autoCrop: !force_ratio }
      } else {
        alert('No media') // TODO: Debug
      }
    },
    onImageCropped(media) {
      const self = this
      const folder = self.cropper.options.folder || 'default'
      const related_id = self.cropper.options.related_id || self.form.id
      const destination = self.cropper.options.destination || 'current'
      self.cropper = { type: '', modal: false, media: {}, options: {} }
      if (media && media.blob) {
        const formData = new FormData() // TODO: { direct object ? }
        formData.append('id', media.id || 0)
        formData.append('file', media.blob, media.id ? null : `${media.pivot_type}-${related_id}.png`)
        formData.append('pivot', related_id)
        formData.append('pivot_type', media.pivot_type)
        formData.append('folder', folder) // TODO: Dynamic
        self.$http
          .post('media/file/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => {
            if (response.data.media) {
              self.form.media = self.form.media || []
              const idx = self.form.media.findIndex(item => item.pivot_type === media.pivot_type)
              media = { ...response.data.media, pivot_type: media.pivot_type }
              if (idx >= 0) self.form.media[idx] = media
              else self.form.media.push(media)
              self.$set(self.images[destination], media.pivot_type, response.data.media)
            }
          })
          .catch(error => {
            console.error(error)
            //self.$router.push({ path: '/login' })
          })
      }
    },
    handleFileSelected(pivot_type, files, event, options) {
      const self = this
      const reader = new FileReader()
      reader.readAsDataURL(files[0])
      reader.onload = e => {
        self.$set(self.images.temp, pivot_type, { id: 0, model_id: 0, pivot_type, url: e.target.result })
        self.cropImage('temp', pivot_type, options) // TODO: use property id or define the folder_id
      }
    }
  }
}
