<template>
  <CRow>
    <!--CCol col="12" lg="10" xl="8"-->
    <CCol col="12">
      <!--CToaster :autohide="8000">
        <template v-for="(alert, index) in alerts">
          <CToast :show="true" :color="alert.type" :key="index"> {{ $t(alert.message) }} </CToast>
        </template>
      </CToaster-->

      <div v-if="cropper.media">
        <ACropper :id="cropper.type" :media="cropper.media" :options="cropper.options" @imageCropped="onImageCropped" />
      </div>

      <!-- // TODO: Global option ? -->
      <CModal :title="modal.title" :color="modal.color" :show.sync="modal.show" :centered="true" @update:show="(status, event, accept) => modal.callback(accept)">
        <h3 class="text-center">{{ $t(modal.title) }}</h3>
        <span v-if="modal.data">
          <CInput v-if="modal.data.email" :label="$t('Prefix subject')" v-model="modal.data.prefix" />
          <AMultiSelect v-if="modal.data.email && modal.data.periods" name="period_id" :horizontal="{ label: 'col-sm-12', input: 'col-sm-12' }" :label="$t('Period')" v-model="modal.data.month_id" :options="modal.data.periods" />
        </span>
        <span v-else>{{ modal.text }}</span>
      </CModal>

      <CCard no-header :accentColor="isFormDirty ? (isFormValid ? 'success' : 'danger') : ''">
        <CCardBody>
          <h3>{{ form.id ? $t('Edit property') + ` id: ${form.id}` : $t('Create property') }}</h3>
          <CForm autocomplete="off" @keydown="clearErrors($event.target.name || 'test')">
            <CTabs ref="tabs" variant="pills" :active-tab.sync="active_tab">
              <CTab ref="properties_tab" :title="$t('Properties')" :class="{ 'tabs-errors': tabs_errors.properties_tab }">
                <ACard title="Basic Information">
                  <AMultiSelect name="property_type_id" label="Type" v-model="form.property_type_id" :options="lists.property_types" :isValid="isValid('property_type_id')" :errors="getErrors('property_type_id')" />
                  <AInput name="name" label="Name" v-model="form.name" :isValid="isValid('name')" :errors="getErrors('name')" />
                  <AInput name="short_name" label="Short name" v-model="form.short_name" :isValid="isValid('short_name')" :errors="getErrors('short_name')" />
                  <ACheckBox label="Active" placeholder="Enable or disable de current property" v-model="form.status_id" />
                  <hr />
                  <ACheckBox label="Print invoices" placeholder="Requires printed invoices" v-model="form.is_printable" />
                  <ACheckBox label="Print non email only" placeholder="Print only for clients without email registered" v-model="form.is_email_printable" />
                  <ACheckBox label="Copy to admin" placeholder="Send invoice copy to administrator" v-model="form.send_copy_to_admin" />
                  <ACheckBox label="Send receipts" placeholder="Send receipts in different batch" v-model="form.send_receipts" />
                  <hr />
                  <ACheckBox label="Show modified invoice" placeholder="Allow owners to see modified invoice when working on credit notes CR/DB" v-model="form.show_owner_fixed_invoices" />
                </ACard>

                <ACard title="Identification and Location">
                  <AMaskedInput name="identification" :horizontal="{ input: 'col-sm-5' }" label="NIT" maxLength="14" v-model="form.identification" mask="NIT" :isValid="isValid('identification')" :errors="getErrors('identification')" />
                  <AMultiSelect name="city_id" :horizontal="{ input: 'col-sm-5' }" label="City" v-model="form.city_id" :options="lists.cities" :isValid="isValid('city_id')" :errors="getErrors('city_id')" />
                  <AInput name="address" label="Address" v-model="form.address" :isValid="isValid('address')" :errors="getErrors('address')" />
                  <AMaskedInput name="phone" :horizontal="{ input: 'col-sm-5' }" label="Phone" v-model="form.phone" mask="phone" :isValid="isValid('phone')" :errors="getErrors('phone')" />
                  <AMaskedInput name="email" :horizontal="{ input: 'col-sm-5' }" label="Email" v-model="form.email" mask="email" :isValid="isValid('email')" :errors="getErrors('email')" />
                </ACard>

                <!--ACard title="Late payments">
                  <ACheckBox label="Surcharge late payment" placeholder="Apply surcharge when paid after day of month" v-model="form.late_payment" />
                  <AInput :horizontal="{ input: 'col-sm-4' }" label="% Surcharge" type="number" max="100" v-model="form.late_payment_percentage" />
                  <AInput name="late_payment_day_of_month" label="After" type="number" min="1" max="31" placeholder="After day of month" helper="(Not included)" v-model="form.late_payment_day_of_month" :isValid="isValid('late_payment_day_of_month')" :errors="getErrors('late_payment_day_of_month')" />
                  <AMultiSelect horizontal label="Type" v-model="form.late_payment_type_id" :options="lists.late_payment_types" />
                </ACard-->

                <ACard title="Billing for expenses">
                  <ACheckBox label="Bill for expenses" placeholder="Administration value calculated based on property expenses" v-model="form.billing_expenses" />
                  <AInput name="constructor_name" label="Constructor" placeholder="Constructor's name" v-model="form.constructor_name" :isValid="isValid('constructor_name')" :errors="getErrors('constructor_name')" />
                  <AMaskedInput name="constructor_identification" :horizontal="{ input: 'col-sm-5' }" label="NIT" placeholder="Constructor's NIT" maxLength="14" v-model="form.constructor_identification" mask="NIT" :isValid="isValid('constructor_identification')" :errors="getErrors('constructor_identification')" />
                </ACard>

                <ACard title="Parameters">
                  <AInput name="consecutive_invoices" label="Invoices consecutive" type="number" v-model="form.consecutive_invoices" :isValid="isValid('consecutive_invoices')" :errors="getErrors('consecutive_invoices')" />
                  <AInput name="consecutive_receipts" label="Receipts consecutive" type="number" v-model="form.consecutive_receipts" :isValid="isValid('consecutive_receipts')" :errors="getErrors('consecutive_receipts')" />
                  <AInput name="consecutive_expenses" label="Expenses consecutive" type="number" v-model="form.consecutive_expenses" :isValid="isValid('consecutive_expenses')" :errors="getErrors('consecutive_expenses')" />
                  <AInput name="late_fees" label="Late fees" type="number" v-model="form.late_fees" :isValid="isValid('late_fees')" :errors="getErrors('late_fees')" />
                </ACard>

                <ACard title="Owners portal">
                  <ACheckBox label="Certification of good" placeholder="Allow owners to print their own Certification of good" v-model="form.certification_portal" />
                </ACard>

                <ACard title="Legal representative">
                  <AInput name="representative_position" label="Position" placeholder="Representative's position" v-model="form.representative_position" :isValid="isValid('representative_position')" :errors="getErrors('representative_position')" />
                  <AInput name="representative_name" label="Name" placeholder="Representative's name" v-model="form.representative_name" :isValid="isValid('representative_name')" :errors="getErrors('representative_name')" />
                  <AMultiSelect name="representative_identification_type_id" :horizontal="{ input: 'col-sm-5', label: 'col-sm-3' }" label="Identification type" v-model="form.representative_identification_type_id" :options="lists.identification_types" :isValid="isValid('representative_identification_type_id')" :errors="getErrors('representative_identification_type_id')" />
                  <AMaskedInput name="representative_identification" :horizontal="{ input: 'col-sm-5' }" label="Identification" placeholder="Representative's identification" maxLength="14" v-model="form.representative_identification" :mask="form.representative_identification_type_id === 31 ? 'NIT' : 'ID'" :isValid="isValid('representative_identification')" :errors="getErrors('representative_identification')" />
                </ACard>
              </CTab>

              <CTab :disabled="!form.id" ref="aplicamos_tab" :title="$t('Aplicamos')" :class="{ 'tabs-errors': tabs_errors.aplicamos_tab }">
                <ACard v-if="emails.ready" title="Notifications">
                  <AInputArray type="email" v-model="form.email_revision_billing" :items="emails.email_revision_billing || ['']" label="Revision emails" placeholder="Revision email" :isValid="isValid" :errors="getErrors" @arrayChanged="value => (form.email_revision_billing = value)" />
                  <hr />
                  <AInputArray type="email" v-model="form.email_close_billing" :items="emails.email_close_billing || ['']" label="Reconciliation emails" placeholder="Reconciliation email" :isValid="isValid" :errors="getErrors" @arrayChanged="value => (form.email_close_billing = value)" />
                  <hr />
                  <AInputArray type="email" v-model="form.email_close_accounting" :items="emails.email_close_accounting || ['']" label="Balancing emails" placeholder="Balancing email" :isValid="isValid" :errors="getErrors" @arrayChanged="value => (form.email_close_accounting = value)" />
                  <hr />
                  <AInputArray type="email" v-model="form.email_collecting_bill" :items="emails.email_collecting_bill || ['']" label="Billing emails" placeholder="Billing email" :isValid="isValid" :errors="getErrors" @arrayChanged="value => (form.email_collecting_bill = value)" />
                </ACard>

                <ACard title="Identification and Location">
                  <AMultiSelect :horizontal="{ input: 'col-sm-5' }" label="Biller" v-model="form.biller_id" :options="lists.billers" />
                </ACard>

                <ACard title="Rate">
                  <AMultiSelect :horizontal="{ input: 'col-sm-5' }" label="Rate" v-model="form.rate_id" :options="lists.rates" />
                </ACard>

                <ACard title="Property manager">
                  <AMultiSelect :horizontal="{ input: 'col-sm-5' }" label="Manager" v-model="form.manager_id" :options="lists.managers" @select="parseDocuments(true)" />
                  <ACheckBox label="Revision" placeholder="Requires administrator approval to print" v-model="form.review_required" />
                  <hr />
                  <AMultiSelect :horizontal="{ input: 'col-sm-5' }" label="Billing account" v-model="form.billing_account_id" :options="lists.billing_accounts" />
                  <ACheckBox label="RETEFUENTE" placeholder="Generate RETEFUENTE" v-model="form.billing_account_retention" />
                  <ACheckBox label="RETEICA" placeholder="Generate RETEICA" v-model="form.billing_account_reteica" />
                </ACard>

                <ACard title="Invoicing form">
                  <AMultiSelect :horizontal="{ input: 'col-sm-5' }" label="Form type" v-model="form.form_type_id" :options="lists.form_types" />
                </ACard>

                <ACard title="Logo">
                  <div v-if="images.current.logo" class="p-2 border bg-light text-center" style="overflow: auto;">
                    <div class="" style="height: auto;">
                      <img class="" :src="images.current.logo.url" style="width: 100%; max-width: 300px;" click="cropImage('current', 'logo', { aspectRatio: 1, folder: 'properties'  })" />
                    </div>
                    {{ images.current.logo.id }}
                  </div>
                  <CInputFile class="mt-2" :value="cropper.file" :horizontal="{ input: 'col-lg-4 col-sm-6' }" type="file" @change="(files, event) => handleFileSelected('logo', files, event, { aspectRatio: false, folder: 'properties' })" :placeholder="$t('New file')" :custom="true" accept="image/x-png,image/jpeg" />
                </ACard>

                <ACard title="QR">
                  <div v-if="images.current.qr" class="p-2 border bg-light text-center" style="overflow: auto;">
                    <div class="" style="height: auto;">
                      <img class="" :src="images.current.qr.url" style="width: 100%; max-width: 300px;" click="cropImage('current', 'qr', { aspectRatio: 1, autoCropArea: 1, folder: 'properties' })" />
                    </div>
                    {{ images.current.qr.id }}
                  </div>
                  <CInputFile class="mt-2" :value="cropper.file" :horizontal="{ input: 'col-lg-4 col-sm-6' }" type="file" @change="(files, event) => handleFileSelected('qr', files, event, { aspectRatio: 1, autoCropArea: 1, folder: 'properties' })" :placeholder="$t('New file')" :custom="true" accept="image/x-png,image/jpeg" />
                </ACard>

                <ACard title="Representative Signature">
                  <div v-if="images.current.legalsig" class="p-2 border bg-light text-center" style="overflow: auto;">
                    <div class="" style="height: auto;">
                      <img class="" :src="images.current.legalsig.url" style="width: 100%; max-height: 150px; max-width: 300px;" click="cropImage('current', 'legalsig', { aspectRatio: false, initialAspectRatio: 3.1, folder: 'properties' })" />
                    </div>
                    {{ images.current.legalsig.id }}
                  </div>
                  <CInputFile class="mt-2" :value="cropper.file" :horizontal="{ input: 'col-lg-4 col-sm-6' }" type="file" @change="(files, event) => handleFileSelected('legalsig', files, event, { aspectRatio: false, initialAspectRatio: 3.1, folder: 'properties' })" :placeholder="$t('New file')" :custom="true" accept="image/x-png,image/jpeg" />
                </ACard>

                <ACard title="Representative Signature">
                  <div v-if="images.current.receiptsig" class="p-2 border bg-light text-center" style="overflow: auto;">
                    <div class="" style="height: auto;">
                      <img class="" :src="images.current.receiptsig.url" style="width: 100%; max-height: 150px; max-width: 300px;" click="cropImage('current', 'receiptsig', { aspectRatio: false, initialAspectRatio: 3.1, folder: 'properties' })" />
                    </div>
                    {{ images.current.receiptsig.id }}
                  </div>
                  <CInputFile class="mt-2" :value="cropper.file" :horizontal="{ input: 'col-lg-4 col-sm-6' }" type="file" @change="(files, event) => handleFileSelected('receiptsig', files, event, { aspectRatio: false, initialAspectRatio: 3.1, folder: 'properties' })" :placeholder="$t('New file')" :custom="true" accept="image/x-png,image/jpeg" />
                </ACard>
              </CTab>

              <CTab :disabled="!form.id" ref="documents_tab" :title="$t('Documents')" :class="{ 'tabs-errors': tabs_errors.documents_tab }">
                <ACard>
                  <CAlert v-if="!form.manager_id" color="danger">{{ $t('You must select the manager first.') }}</CAlert>
                  <div v-else>
                    <div v-for="(type, index) of lists.document_types" :key="index + form.manager_id">
                      <PropertyDocument :label="type.name" :document="form.documents[index]" :contacts="__list_manager_contacts" @documentChanged="value => (form.documents[index] = value)" />
                      <hr />
                    </div>
                  </div>
                </ACard>
              </CTab>

              <CTab :disabled="!form.id" ref="accounting_tab" :title="$t('Accounting')" :class="{ 'tabs-errors': tabs_errors.accounting_tab }">
                <div v-for="(connection, index) in form.connections" :key="index">
                  <ACard :title="$t('IC. ') + ` ${objects.connections[connection.connection_type_id].name}`">
                    <!-- // TODO: add sfv validation -->
                    <PropertyConnection :connection="connection" :entity="objects.connections[connection.connection_type_id]" :validation="[`connections.${index}.`]" @connectionChanged="value => $set(form.connections, index, value)" />
                  </ACard>
                </div>
              </CTab>

              <CTab :disabled="!form.id" ref="pse_tab" :title="$t('PSE')" :class="{ 'tabs-errors': tabs_errors.pse_tab }">
                <ACard no-title="$t('PSE Provider')">
                  <AMultiSelect :horizontal="{ input: 'col-sm-5' }" label="PSE Provider" v-model="form.payment_provider_id" :options="lists.payment_providers" @select="onProviderChanged" />

                  <div v-for="provider of form.providers" :key="provider.id">
                    <ACard v-show="provider.id === form.payment_provider_id" :title="provider.name">
                      <PaymentProvider :provider="provider" :lists="lists" :validation="[`providers.${provider.model}.data.`, isValid, getErrors]" @providerChanged="value => (provider.data = value)" />
                    </ACard>
                  </div>
                </ACard>
              </CTab>

              <CTab :disabled="!form.id" ref="forms_tab" :title="$t('Forms')" :class="{ 'tabs-errors': tabs_errors.forms_tab }">
                <ACard no-title="$t('Receipt forms')">
                  <AMultiSelect :horizontal="{ input: 'col-sm-5' }" label="Print receipt" v-model="form.bill_form_print_id" :options="lists.bill_forms" />
                  <AMultiSelect :horizontal="{ input: 'col-sm-5' }" label="Digital receipt" v-model="form.bill_form_digital_id" :options="lists.bill_forms" />
                </ACard>
              </CTab>

              <CTab :disabled="!form.id" ref="envelopes_tab" :title="$t('Envelopes')" :class="{ 'tabs-errors': tabs_errors.envelopes_tab }">
                <ACard>
                  <div v-for="(envelope, index) of form.envelopes" :key="index">
                    <transition name="fade">
                      <ACard :title="$t('Envelope') + ` ${index + 1}`">
                        <template #append>
                          <CInputCheckbox class="float-right" :label="$t('Enabled')" custom :checked="!!envelope.status_id" v-model="envelope.status_id" @update:checked="value => (envelope.status_id = value)" />
                        </template>
                        <PropertyEnvelope :envelope="envelope" :index="index" :validation="[`envelopes.$each.${index}.`, isValid, getErrors]" @envelopeChanged="value => $set(form.envelopes, index, value)" />
                        <CCardFooter>
                          <CButton color="danger" class="pull-right" @click="removeEnvelope(index, envelope)">{{ '- ' + $t('Remove envelope') }}</CButton>
                        </CCardFooter>
                      </ACard>
                    </transition>
                  </div>
                  <CCardFooter>
                    <CButton color="primary" class="pull-right" @click="addEnvelope">{{ '+ ' + $t('Add envelope') }}</CButton>
                  </CCardFooter>
                </ACard>
              </CTab>

              <CTab :disabled="!form.id" ref="modules_tab" :title="$t('Modules')" :class="{ 'tabs-errors': tabs_errors.modules_tab }">
                <ACard>
                  <div v-for="(module, index) of form.modules" :key="index">
                    <transition name="fade">
                      <ACard :title="$t('Module') + ` ${index + 1}`">
                        <template #append>
                          <CInputCheckbox class="float-right" :label="$t('Enabled')" custom :checked="!!module.status_id" v-model="module.status_id" @update:checked="value => (module.status_id = value)" />
                        </template>
                        <PropertyModule :module="module" :index="index" :validation="[`modules.$each.${index}.`, isValid, getErrors]" @moduleChanged="value => $set(form.modules, index, value)" />
                        <CCardFooter>
                          <CButton color="danger" class="pull-right" @click="removeModule(index, module)">{{ '- ' + $t('Remove module') }}</CButton>
                        </CCardFooter>
                      </ACard>
                    </transition>
                  </div>
                  <CCardFooter>
                    <CButton color="primary" class="pull-right" @click="addModule">{{ '+ ' + $t('Add module') }}</CButton>
                  </CCardFooter>
                </ACard>
              </CTab>

              <CTab :disabled="!form.id" ref="processes_tab" :title="$t('Processes')" :class="{ 'tabs-errors': tabs_errors.processes_tab }">
                <transition name="fade">
                  <ACard>
                    <ACard :title="$t('Reset property')">
                      <CButton class="mr-2" color="primary" @click="onResetProperty(false)">{{ $t('Parcial') }}</CButton>
                      <CButton class="mr-2" color="danger" @click="onResetProperty(true)">{{ $t('Total') }}</CButton>
                    </ACard>
                    <ACard v-if="periods._status" :title="$t('Close property')">
                      <CButton class="mr-2" :color="periods._status === 'FAC' ? 'primary' : 'danger'" @click="onCloseProperty(false, periods._status === 'REC')">{{ (periods._status === 'REC' ? $t('Reverse') + ' ' : '') + $t('Invoicing') }}</CButton>
                      <CButton class="mr-2" :color="periods._status === 'REC' ? 'primary' : 'danger'" @click="onCloseProperty(true, periods._status === 'FAC')">{{ (periods._status === 'FAC' ? $t('Reverse') + ' ' : '') + $t('Collecting') }}</CButton>
                    </ACard>
                    <ACard v-if="periods._status" :title="$t('Generate invoices')">
                      <CButton class="mr-2" color="danger" @click="onGenerateInvoices(true)">{{ $t('Email invoices') + ` (${periods.REC._period_name})` }}</CButton>
                      <CButton class="mr-2" color="primary" @click="onGenerateInvoices(false)">{{ $t('Generate invoices') + ` (${periods.REC._period_name})` }}</CButton>
                      <CButton class="ml-2" color="secondary" @click="onGenerateEnvelopes">{{ $t('Generate envelopes') + ` (${periods.REC._period_name})` }}</CButton>
                      <CButton v-if="form.send_receipts" class="ml-2" color="secondary">{{ $t('Send receipts') + ` (${periods.REC._period_name})` }}</CButton>
                    </ACard>

                    <ACard v-if="event.event === 'closingPeriod'" :title="$t('Close property') + ' ' + $t('Status')">
                      <div v-for="(status, task) of event.tasks" :key="task">
                        <CIcon v-if="status === null" name="cil-x" />
                        <CIcon v-else :name="status ? 'cil-check' : 'cil-ban'" />
                        {{ task }}
                      </div>
                      <br />
                      <br />
                      <div v-if="event.errors.length">
                        <b>{{ $t('Errors summary') }}</b>
                        <ul>
                          <li v-for="errors of event.errors" :key="errors.name">
                            <strong>{{ $t(errors.name) }}</strong>
                            <ol>
                              <li v-for="error of errors.errors" :key="error">{{ $t(error) }}</li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </ACard>
                  </ACard>
                </transition>
              </CTab>

              <CTab :disabled="!form.id" :title="$t('Parameters')">
                <ACard>
                  <transition name="fade">
                    <ACard :title="$t('System settings')">
                      <AInput :horizontal="{ input: 'col-sm-3' }" type="number" name="shares_delta" label="Shares delta" :min="1 / 10 ** (settings.shares_delta_precision || 3)" v-model="form.shares_delta" />
                    </ACard>
                  </transition>
                </ACard>
              </CTab>

              <CTab :disabled="!form.id" :title="$t('Audits')"></CTab>
            </CTabs>
          </CForm>
          <CCardFooter>
            <CButton class="mr-2" :color="form.id ? 'info' : 'primary'" adisabled="!isFormValid" @click="submit">{{ $t(form.id ? 'Save' : 'Create') }}</CButton>
            <CButton color="secondary" @click="goBack">{{ $t('Back') }}</CButton>
          </CCardFooter>
        </CCardBody>
      </CCard>
    </CCol>

    <!-- // TODO: Debug view... create a global component -->
    <!--CCol v-if="DEBUG" col="12" lg="2" xl="4" style="position: fixed; bottom: 0; right: 0; max-height: calc(100vh - 110px); max-width: 25%; overflow: auto;"-->
    <CCol v-if="DEBUG" col="12">
      <pre>{{ form }}</pre>
      <div class="summary text-red" v-if="!_.isEmpty(this.showErrors)">
        Form has errors
        <pre>{{ showErrors }}</pre>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import formMixin from '@/app/views/_mixins/form-mixin'
import filesMixin from '@/app/views/_mixins/files-mixin'

import PropertyModule from './elements/PropertyModule'
import PaymentProvider from './elements/PaymentProvider'
import PropertyDocument from './elements/PropertyDocument'
import PropertyEnvelope from './elements/PropertyEnvelope'
import PropertyConnection from './elements/PropertyConnection'

import { generateInvoices, generateEnvelopes } from '@/app/_utils/process-utils'
import { DEBUG } from '@/config/config'

export default {
  name: 'PropertyForm',
  mixins: [formMixin, filesMixin],
  components: {
    PropertyModule,
    PaymentProvider,
    PropertyDocument,
    PropertyEnvelope,
    PropertyConnection
  },
  computed: {
    __list_manager_contacts() {
      const contacts = this.objects?.manager_contacts[this.form.manager_id]
      return contacts ? contacts.__list : []
    }
  },
  data: () => {
    return {
      DEBUG: DEBUG, // TODO: Global or in Helper

      // DATA
      form: { id: 0, documents: [], modules: [], envelopes: [], connections: [], providers: {} },

      // Extra
      lists: {},
      event: {},
      images: { current: {}, temp: {} },

      // Helpers
      //alerts: [],
      active_tab: 0,
      emails: { ready: false },
      objects: {},
      cropper: { type: '', modal: false, media: {}, options: {} },
      validators: {},
      tabs_errors: {
        properties_tab: false,
        aplicamos_tab: false,
        documents_tab: false,
        accounting_tab: false,
        pse_tab: false,
        forms_tab: false,
        envelopes_tab: false,
        modules_tab: false,
        processes_tab: false
      }
    }
  },
  created() {
    this.form.id = this.$route.params.id || 0
    this.getData()

    if (this.form.id) {
      const channel = this.$pusher.subscribe(`channel-${this.form.id}`) // TODO: unbind beforeDestroy ???
      channel.bind(`event-${this.form.id}`, data => {
        if (data.message === 'closingPeriod') {
          this.event = data.event // TODO: filter by correct message (or use specific channel - interest)
        }
      })
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getData() {
      const self = this
      self.$http
        .get('admin/properties' + (self.form.id ? `/${self.form.id}/edit` : '/create'))
        .then(response => {
          self.parseData(response.data)
        })
        .catch(error => {
          console.error(error)
          self.showAlert('There was an error.', 'danger')
          //self.goBack() // TODO: Alert
          //self.$router.push({ path: 'login' })
        })
    },

    // Parse Extra and Related data
    parseData(data) {
      this.form = data.property ? data.property : { id: 0 }
      this.lists = data._lists || {}

      this.parseView()
      this.parseMedia()
      this.parseEmails()
      this.parseModules()
      this.parseContacts()
      this.parseEnvelopes()
      this.parseDocuments()
      this.parseConnections()
      this.parsePaymentProviders()

      this.validators = {
        main: data._validation || {},
        extra: data._extra_validation || {}
      }

      if (_.isEmpty(this.$v.$form)) this.parseValidator(data._validation, data._messages, true)

      if (this.form.id) this.forcedSteps()
    },
    forcedSteps() {
      this.onProviderChanged()
    },

    // Parsing

    parseView() {
      const options = [
        { click: this.submit, class: 'mr-2 btn-' + (this.form.id ? 'info' : 'primary'), content: this.$t(this.form.id ? 'Save' : 'Create') }, //disabled: this.isFormValid },
        { click: this.goBack, class: 'btn-secondary', content: this.$t('Back') }
      ]
      this.$store.dispatch('setTopActions', { [(this.form.id ? 'Edit' : 'Create') + 'Property']: options })
    },
    parseEmails() {
      this.emails.ready = false
      this.form.email_close_billing = (this.form.email_close_billing || '').trim().split(';')
      this.form.email_collecting_bill = (this.form.email_collecting_bill || '').trim().split(';')
      this.form.email_close_accounting = (this.form.email_close_accounting || '').trim().split(';')
      this.form.email_revision_billing = (this.form.email_revision_billing || '').trim().split(';')

      this.$nextTick(() => {
        this.emails.email_close_billing = this.form.email_close_billing.slice()
        this.emails.email_collecting_bill = this.form.email_collecting_bill.slice()
        this.emails.email_close_accounting = this.form.email_close_accounting.slice()
        this.emails.email_revision_billing = this.form.email_revision_billing.slice()
        this.emails.ready = true
      })
    },
    parseMedia() {
      if (this.form.media) {
        for (const media of this.form.media) {
          this.$set(this.images.current, media.pivot_type, media)
        }
      }
    },
    parseContacts() {
      this.objects.manager_contacts = {}
      for (const contact of this.lists.manager_contacts) {
        if (typeof this.objects.manager_contacts[contact.manager_id] === 'undefined') this.objects.manager_contacts[contact.manager_id] = { __list: [] }
        this.objects.manager_contacts[contact.manager_id].__list.push(contact)
      }
    },
    parseDocuments(reset) {
      if (this.lists.document_types) {
        const documents = []
        for (const type of this.lists.document_types) {
          const document = (this.form.documents || []).find(document => document.type_id == type.id)
          documents.push(document && !reset ? document : { id: 0, property_id: this.form.id, type_id: type.id, manager_contact_id: 0, manager_logo: 0 })
        }
        this.$set(this.form, 'documents', [])
        this.$nextTick(() => this.$set(this.form, 'documents', documents)) // Force update
      }
    },
    parseModules() {
      if (this.form.modules && this.form.modules.length) this.validateModules()
    },
    parseEnvelopes() {
      if (this.form.envelopes && this.form.envelopes.length) this.validateEnvelopes()
    },
    parseConnections() {
      this.objects.connections = {}
      for (let connection in this.lists.connection_types) {
        connection = this.lists.connection_types[connection]
        connection.__list = this.lists.connections.filter(item => item.connection_type_id == connection.id) || []
        this.objects.connections[connection.id] = { ...connection, sfv: connection.id === 'FAC' }
      }

      this.form.connections = this.form.connections || []
      const connections = [{ id: 'FAC' }, { id: 'PAG' }, { id: 'NOT' }, { id: 'DCT' }] // TODO: Dynamic (order)
      for (const pos in connections) {
        const connection = this.form.connections.find(connection => connection.connection_type_id === connections[pos].id) // Only first one ?
        connections[pos] = connection || { id: 0, connection_id: 0, connection_type_id: connections[pos].id }
      }
      this.$set(this.form, 'connections', connections)
    },
    parsePaymentProviders() {
      // TODO: Dynamic ? (Enabled providers ?)
      if (this.form.providers) {
        this.form.providers = {
          pse_commerce: { ...{ id: 1, name: 'Zona Virtual', model: 'pse_commerce', data: {} }, ...(this.form.providers.pse_commerce || {}) },
          cpv_contracts: { ...{ id: 2, name: 'CPV AV Villas', model: 'cpv_contracts', data: {} }, ...(this.form.providers.cpv_contracts || {}) }
        }
      }
    },

    // Modules
    addModule() {
      if (this.form.modules.length < 10) {
        this.form.modules.push({ id: 0 })
      }
      if (this.form.modules.length === 1) this.validateModules()
    },
    removeModule(index, module) {
      if (module.id) this.form.remove_modules = [...(this.form.remove_modules || []), ...[module.id]]
      this.form.modules.splice(index, 1)
      if (this.form.modules.length === 0) this.removeValidator('modules')
    },
    validateModules() {
      this.$nextTick(() => {
        if (this.validators?.extra?.modules) this.injectValidator('modules', 0)
      })
    },

    // Envelopes
    addEnvelope() {
      if (this.form.envelopes.length < 10) {
        this.form.envelopes.push({ id: 0, show_content: true })
      }
      if (this.form.envelopes.length === 1) this.validateEnvelopes()
    },
    removeEnvelope(index, envelope) {
      if (envelope.id) this.form.remove_envelopes = [...(this.form.remove_envelopes || []), ...[envelope.id]]
      this.form.envelopes.splice(index, 1)
      if (this.form.envelopes.length === 0) this.removeValidator('envelopes')
    },
    validateEnvelopes() {
      this.$nextTick(() => {
        if (this.validators?.extra?.envelopes) this.injectValidator('envelopes', 0)
      })
    },

    // Events
    onProviderChanged() {
      // TODO: Warning with select-change... not updated yet --- OJO!!!
      this.$nextTick(() => {
        // TODO: Dynamic
        if (this.validators?.extra?.providers) {
          if (this.form.payment_provider_id === 1) this.injectValidator('providers', 'pse_commerce')
          else if (this.form.payment_provider_id === 2) this.injectValidator('providers', 'cpv_contracts')
          else this.removeValidator('providers')
        }
      })
    },
    onResetProperty(is_full) {
      is_full = is_full === true
      this.modal.color = 'warning'
      this.modal.title = this.$t('Reset property')
      this.modal.text = this.$t(`Reset property ${is_full ? 'totally' : 'partially'}`) + '. ' + this.$t('Are you sure?')
      this.modal.show = true
      this.modal.data = { type: 'reset', is_full }
      this.modal.callback = this.processProperty
    },
    onCloseProperty(is_collecting, is_reverse) {
      is_collecting = is_collecting === true
      this.modal.color = 'warning'
      this.modal.title = (is_reverse ? this.$t('Reverse') + ' ' : '') + this.$t('Close property')
      this.modal.text = (is_reverse ? this.$t('Reverse') + ' ' : '') + this.$t(`Close property ${is_collecting ? 'collecting' : 'invoicing'}`) + '. ' + this.$t('Are you sure?')
      this.modal.show = true
      this.modal.data = { type: is_reverse ? 'reverse' : 'close', is_collecting }
      this.modal.callback = this.processProperty
    },

    //

    processProperty(accept) {
      this.event = {}
      this.modal.show = false
      if (!accept) {
        this.showAlert('Canceled', 'warning')
        return
      }

      let type = 'properties/reset'
      if (this.modal.data.type !== 'reset') {
        type = 'processes/' + (this.modal.data.type === 'reverse' ? 'reverse_' : '') + 'close_' + (this.modal.data.is_collecting ? 'collecting' : 'invoicing')
      }

      const self = this
      self.$http
        .post('admin/' + type, self.modal.data) // TODO: on service ?
        .then(response => {
          self.showAlert('Property ' + (this.modal.data.type === 'reset' ? 'reseted' : this.modal.data.type === 'reverse' ? 'reversed' : 'closed'))
          if (this.modal.data.type === 'reset') self.parseData(response.data)
          else console.log(response.data)
        })
        .catch(error => {
          console.error(error)
          self.showAlert('There was an error ' + (this.modal.data.type === 'reset' ? 'resetting' : this.modal.data.type === 'reverse' ? 'reversing' : 'closing') + ' the property.', 'danger')
        })
    },

    // TODO: create a generic function
    onGenerateInvoices(email) {
      this.modal.color = 'warning'
      this.modal.title = email ? this.$t('Email invoices') : this.$t('Generate invoices')
      this.modal.text = this.modal.title + '. ' + this.$t('Are you sure?')
      this.modal.show = true
      this.modal.data = {
        email,
        prefix: 'Factura de Administración - ',
        periods: this.lists.periods,
        month_id: this.lists.periods.length ? this.lists.periods[0].id : 0
      } // TODO: Use trans ?
      this.modal.callback = this.callGenerateInvoices
    },

    onGenerateEnvelopes() {
      this.modal.color = 'warning'
      this.modal.title = this.$t('Generate envelopes')
      this.modal.text = this.modal.title + '. ' + this.$t('Are you sure?')
      this.modal.show = true
      this.modal.data = { periods: this.lists.periods, month_id: this.lists.periods.length ? this.lists.periods[0].id : 0 }
      this.modal.callback = this.callGenerateEnvelopes
    },

    callGenerateInvoices(accept) {
      this.event = {}
      this.modal.show = false
      if (accept) generateInvoices(this.modal.data, this)
      else this.showAlert('Canceled', 'warning')
    },

    callGenerateEnvelopes(accept) {
      this.event = {}
      this.modal.show = false
      if (accept && this.modal.data.month_id) generateEnvelopes(this, this.modal.data.month_id)
      else this.showAlert('Canceled', 'warning')
    },

    // SUBMIT

    submit() {
      this.setErrors({})
      Object.keys(this.tabs_errors).forEach(key => (this.tabs_errors[key] = false))
      this.$v.form.$touch()

      const alert = `There was an error ${this.form.id ? 'saving' : 'creating'} the property.`

      if (this.$v.form.$error) {
        this.locateErrors(alert)
        return
      }

      // Parse Before submit ?
      this.form.email_close_billing = this.form.email_close_billing.filter(email => email !== '')
      this.form.email_collecting_bill = this.form.email_collecting_bill.filter(email => email !== '')
      this.form.email_revision_billing = this.form.email_revision_billing.filter(email => email !== '')
      this.form.email_close_accounting = this.form.email_close_accounting.filter(email => email !== '')

      const self = this
      self.form = self.stripNumbers(self.form, ['identification', 'phone', 'representative_identification', 'constructor_identification'])
      self.$http[self.form.id ? 'put' : 'post']('admin/properties', self.form) // TODO: on service ?
        .then(response => {
          self.$router.replace(`/admin/properties/${response.data.property.id}/edit`).catch(() => {})
          self.showAlert(`Property ${self.form.id ? 'saved' : 'created'}.`)
          self.parseData(response.data)
        })
        .catch(error => {
          // TODO: move to form helper ?
          //if (error && error.status === 422) {
          if (error.response && error.response.status === 422) {
            self.setErrors(error.response.data.errors || {})
            self.locateErrors(alert)
          }
          if (error.response?.data?.message == 'The given data was invalid.') {
            self.showAlert(alert, 'danger')
            for (const key in error.response.data.errors) {
              if (error.response.data.errors[key]) {
                self.message += error.response.data.errors[key][0] + '  '
              }
            }
          } else {
            console.error(error)
            self.showAlert(alert, 'danger')
            //self.goBack() // TODO: login ?
          }
        })
    }
  }
}
</script>

<style lang="scss">
.tabs-errors {
  .nav-link:after {
    //content: '❗';
    content: '🔺';
  }
}
</style>
