<template>
  <div>
    <CInput horizontal readonly :label="$t('Document')" :value="label" />
    <AMultiSelect label="Responsible contact" v-model="state.manager_contact_id" :options="contacts" @change="onChange" />
    <!--CSelect horizontal :label="$t('Responsible contact')" class="plain-select" :value.sync="state.manager_contact_id" model="state.manager_contact_id" :placeholder="$t('Select option')" :options="options" @update="onSelected" /-->
    <ACheckBox label="Manager logo" :disabled="!state.manager_contact_id" placeholder="Show manager's logo on the document." v-model="state.manager_logo" @change="onChange" />
  </div>
</template>

<script>
export default {
  name: 'PropertyDocument',
  props: {
    document: {
      type: Object,
      default: () => {
        return {
          id: 0,
          property_id: 0,
          type_id: 0,
          manager_contact_id: 0,
          manager_logo: 0
        }
      }
    },
    contacts: {
      type: Array,
      default: () => []
    },
    label: String
  },
  watch: {
    document(val) {
      this.state = val
    }
  },
  data() {
    return {
      state: { ...this.document, manager_contact_id: '' + this.document.manager_contact_id }
      //options: [] // this.contacts
    }
  },
  mounted() {
    //this.options = this.contacts.map(contact => ({ value: contact.id, label: contact.name, attrs: { class: { 'p-2': true } } }))
    this.state.manager_contact_id = this.document.manager_contact_id
  },
  methods: {
    onChange(e) {
      console.log('CHANGE')
      this.$emit('documentChanged', this.state, e)
    }
  }
}
</script>
