import { DATEFORMAT } from '@/config/config'

export default {
  computed: {
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn']
    },
    loggedUser() {
      return this.$store.getters['auth/loggedUser']
    },
    userExtra() {
      return this.$store.getters['auth/userExtraData']
    },
    account() {
      return this.$store.getters['auth/userAccount']
    },
    property() {
      return this.$store.getters['auth/userProperty']
    },
    settings() {
      return this.userExtra?.settings || {}
    },
    periods() {
      return this.property._periods || {}
    },
    period() {
      return this.periods._status ? this.periods[this.periods._status] : {}
    }
  },
  data() {
    return {
      modal: {
        show: false
      },
      events: false,

      arrays: {
        validIcons: ['cil-ban', 'cil-check'],
        validColors: ['secondary', 'primary']
      },

      formats: {
        DATEFORMAT
      },

      parsers: {
        numeric: (value, type, decimal) => this.$f(value, type || 'decimal', decimal === undefined ? 2 : decimal),
        //datetime: (timestamp, format) => this.$moment.utc(timestamp).format(format || `${DATEFORMAT} hh:mm:ss`),
        datetime: (timestamp, format, fromFormat) => this.$d(timestamp, format, fromFormat),
        readIndex: (id, object) => object[id || 0] || 'no-index',
        periodName: month_id => this.getPeriodName(month_id),
        readHelper: (id, helper, label, trans) => (trans ? this.$t((this.getHelperItem(id, helper) || {})[label]) : (this.getHelperItem(id, helper) || {})[label]),
        buildingFull: building => `${building.type.name || '--'} ${building.unit.name}-${building.code}` // TODO: Check the related (type, unit)
      },

      formatters: {
        string: field => ({ field, callback: value => value }), //(isNaN(value) ? value : `\u200C${value}`) }),
        numeric: field => ({ field, callback: value => value }), // TODO: mask for decimals ?
        boolean: field => ({ field, callback: value => ['No', 'Si'][value] || value }),
        datetime: field => ({ field, callback: value => new Date(value) })
      },

      helpers: {}
    }
  },
  created() {
    this.helpers = {
      userName: { key: 'user', helper: { id: '_user.name' } },
      grouper: { key: 'grouper_code', helper: { id: 'grouper.code' }, link: { id: 'grouper.id', href: '#/admin/property/buildings/XXX/edit' }, _style: 'background-color: #45a164;' }, // TODO: variables
      building: { key: 'building_code', helper: { id: 'building.code' }, link: { id: 'building.id', href: '#/admin/property/buildings/XXX/edit' }, _style: 'background-color: #1769ff;' }, // TODO: variables
      rounding: { key: 'rounding', helper: { id: 'rounding_id', parser: id => this.parsers.readHelper(id, 'roundings', 'name') } },
      monthName: { key: 'month', helper: { id: 'month_id', parser: month_id => this.parsers.periodName(month_id), _style: 'width: 120px;' } },
      createdAt: { key: 'created_at', helper: { id: '_created_at', parser: _created_at => this.parsers.datetime(_created_at) }, formatter: 'datetime' },
      inputType: { key: 'input', label: 'Type', helper: { id: 'input_type_id', parser: id => this.parsers.readIndex(id, { CRUD: `${this.$t('Form')} (CRUD)`, PROC: `${this.$t('Process')} (PROC)`, FILE: `${this.$t('File')} (FILE)` }) } },
      ownerName: { key: 'owner_name', helper: { id: 'owner.name' }, link: { id: 'owner.id', href: '#/admin/property/building/contacts/XXX/edit' } },
      simpleTime: { key: 'time', _classes: 'text-center', render: time => (time || '').substr(0, 5) }, // TODO: formatter ?
      natureName: { key: 'nature', helper: { id: 'nature_id', parser: id => this.parsers.readIndex(id, { DB: `${this.$t('Debit')} (DB)`, CR: `${this.$t('Credit')} (CR)` }) } },
      validIcons: { key: 'valid', _classes: 'text-center', icons: this.arrays.validIcons, formatter: 'boolean' },
      periodType: { key: 'period_type', helper: { id: 'period_type_id', parser: id => this.parsers.readIndex(id, { FAC: `${this.$t('Invoicing')} (FAC)`, REC: `${this.$t('Collecting')} (REC)` }) } },
      identifier: { key: 'identifier', helper: { id: 'building.identifier' }, link: { id: 'building.id', href: '#/admin/property/buildings/XXX/edit' }, _style: 'background-color: #1769ff;' }, // TODO: variables
      smallValue: { key: 'value', _classes: 'text-right', formatter: 'numeric', default: 0, render: value => this.parsers.numeric(value, 'decimal', 0), _style: 'width: 100px;' },
      currenOwner: { key: 'owner_name', helper: { id: 'building.current_owner.contact.name' }, link: { id: 'building.current_owner.contact.id', href: '#/admin/property/building/contacts/XXX/edit' } }, // TODO: Normalize use owner !!!
      phoneNumber: { key: 'phone', render: phone => this.parsers.numeric(phone, 'phone') },
      activeBadge: { key: 'active', _classes: 'text-center', _style: 'width: 50px;', helper: { id: 'status_id', parser: id => this.parsers.readIndex(id, [this.$t('Inactive'), this.$t('Active')]) }, badge: { colors: this.arrays.validColors } },
      sharesValue: { key: 'index_cop', label: 'Share', _classes: 'text-right', formatter: 'numeric', default: 0, render: value => this.parsers.numeric(value), _style: 'width: 120px;' },
      //datetimeTime: { key: 'time', _classes: 'text-center', helper: { id: 'time', parser: time => this.parsers.datetime(time, 'HH:mm', 'HH:mm') } },
      datetimeTime: { key: 'time', _classes: 'text-center', render: time => this.parsers.datetime(time, 'HH:mm', 'HH:mm'), _style: 'width: 120px;' },
      //datetimeDate: { key: 'datetime', _classes: 'text-center', helper: { id: 'datetime', parser: datetime => this.parsers.datetime(datetime) } },
      datetimeDate: { key: 'datetime', _classes: 'text-center', render: datetime => this.parsers.datetime(datetime, 'YYYY-MM-DD'), _style: 'width: 120px;' },
      decimalValue: { key: 'value', _classes: 'text-right', formatter: 'numeric', default: 0, render: value => this.parsers.numeric(value), _style: 'width: 150px;' },
      thousandValue: { key: 'value', _classes: 'text-right', formatter: 'numeric', default: 0, render: value => this.parsers.numeric(value, 'decimal', 0), _style: 'width: 150px;' },
      currencyValue: { key: 'value', _classes: 'text-right', formatter: 'numeric', default: 0, render: value => this.parsers.numeric(value || 0, 'currency_signed', 0), _style: 'width: 150px;' },
      ownerNameText: { key: 'owner_name', helper: { id: 'owner.name' } },
      identifierText: { key: 'identifier', label: 'Building', helper: { id: 'building.identifier' } }, // TODO: variables

      bankAccount: {
        key: 'bank_account',
        helper: {
          id: 'bank_account_id',
          parser: id => {
            return this.parsers.readHelper(id, 'bank_accounts', 'name') + ' (' + this.parsers.readHelper(id, 'bank_accounts', 'account_number') + ')'
          }
        }
      },

      buildings: {
        key: 'buildings',
        helper: {
          id: 'building_ids',
          parser: building_ids => {
            if (!building_ids) return ''
            const building_names = []
            building_ids.forEach(building_id => {
              const building = this.view_helpers.buildings[building_id] // TODO: use parsers
              const unit = this.view_helpers.property_units[building.property_unit_id] // TODO: use parsers
              building_names.push(unit.name + '-' + building.code)
            })
            return `[ ${building_names.join(', ')} ]`
          }
        }
      },

      monthNames: {
        key: 'month_names',
        helper: {
          id: 'month_ids',
          parser: month_ids => {
            const month_names = []
            month_ids.forEach(month_id => month_names.push(this.parsers.periodName(month_id)))
            return `[ ${month_names.join(', ')} ]`
          }
        }
      }
    }
  },
  methods: {
    showAlert(message, type, replace) {
      message = this.$t(message)
      this.$store.dispatch('setAlert', { message, type: type || 'success', replace })
    },

    getPeriodName(month_id, format) {
      return this.$moment(month_id, format || 'YYMM').format('MMM') + ('-20' + month_id).substr(0, 5)
    },

    getHelperObject(key, id, helper, label, trans) {
      return { key, helper: { id: id || `${key}_id`, parser: id => this.parsers.readHelper(id, helper || `${key}s`, label || 'name', trans) } }
    },

    getFormattedHelperObject(formatter, key, id, helper, label, trans) {
      return { formatter, ...this.getHelperObject(key, id, helper, label, trans) }
    },

    resetEventToaster() {
      setTimeout(() => {
        this.events = false
      }, 2000)
    },

    prepareHelpers(lists, notify) {
      this.lists = lists
      const helpers = {
        active: { 0: { name: this.$t('Inactive'), color: 'secondary' }, 1: { name: this.$t('Active'), color: 'success' } },
        statuses: { 0: { name: this.$t('Status 0'), color: 'warning' }, 1: { name: this.$t('Status 1'), color: 'danger' } }
      }
      for (const list in lists) {
        helpers[list] = {}
        for (const item of lists[list]) {
          helpers[list][item.id] = item
        }
      }
      if (notify) this.$emit('preparedHelpers', helpers)
      this.view_helpers = helpers
    },

    getHelperItem(id, helper) {
      return (this.view_helpers[helper] || {})[id]
    },

    // User preferences
    getUserPreferences(params, callback) {
      const self = this
      self.$http
        .get('user_preferences', params)
        .then(response => callback(response))
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error retrieving user preferences.`, 'danger')
        })
    },
    setUserPreferences(params, callback) {
      const self = this
      self.$http[params.id ? 'put' : 'post']('user_preferences', params)
        .then(response => {
          if (callback) callback(response)
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error ${params.id ? 'saving' : 'creating'} preferences.`, 'danger')
        })
    },
    removeUserPreferences(id, callback) {
      const self = this
      self.$http
        .delete('user_preferences', { id })
        .then(response => {
          if (callback) callback(response)
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error retrieving deleting preferences.`, 'danger')
        })
    }
  }
}
