<template>
  <!--CForm @submit.prevent="onSubmit" @change="onChange"-->
  <div @change="onChange">
    <!--ACheckBox label="Enabled" placeholder="Si se desactiva el sobre será borrado." v-model="state.status_id" /-->
    <AInput label="Module name" :name="`modules.$each.${index}.name`" v-model="state.name" :isValid="isValid('name')" :errors="getErrors('name')" />
    <AInput label="Description" v-model="state.description" />
  </div>
  <!--/CForm-->
</template>

<script>
export default {
  name: 'PropertyModule',
  props: {
    module: {
      type: Object,
      required: true
    },
    validation: {
      type: Array,
      default: () => ['', false, false]
    },
    index: {
      type: Number,
      required: true
    }
  },
  watch: {
    module(val) {
      this.state = val
    }
  },
  data() {
    return {
      state: { ...this.module }
    }
  },
  methods: {
    onSubmit(e) {
      //console.log('SUBMIT')
    },
    onChange(e) {
      //console.log('CHANGE')
      this.$emit('moduleChanged', this.state, e)
    },
    isValid(field) {
      if (!this.validation[1]) return true
      return this.validation[1](this.validation[0] + field)
    },
    getErrors(field) {
      if (!this.validation[2]) return []
      return this.validation[2](this.validation[0] + field)
    }
  }
}
</script>
