<template>
  <!--CForm @submit.prevent="onSubmit" @change="onChange"-->
  <div @change="onChange">
    <!--ACheckBox label="Enabled" placeholder="Si se desactiva el sobre será borrado." v-model="state.status_id" /-->
    <AInput label="Envelope name" :name="`envelopes.$each.${index}.name`" v-model="state.name" :isValid="isValid('name')" :errors="getErrors('name')" />
    <AInput label="Addressee" v-model="state.addressee" />
    <AInput label="Addressee type" v-model="state.addressee_type" />
    <AInput label="Cellphone" v-model="state.cellphone" />
    <AInput label="Address extra" v-model="state.address_extra" />
    <AInput label="Address" v-model="state.address" />
    <AInput label="City name" v-model="state.city_name" />
    <AInput label="Envelope descriptor" v-model="state.envelope_descriptor" />
    <ACheckBox label="Show content" placeholder="Mostrar la información de contenido en el sobre." v-model="state.show_content" />

    <hr />
    <ACheckBox label="Deliver to" placeholder="Entregar en una dirección diferente." v-model="state.deliver_to" />
    <transition name="fade">
      <div v-show="state.deliver_to">
        <AInput label="Deliver name" v-model="state.deliver_name" />
        <AInput label="Deliver address extra" v-model="state.deliver_address_extra" />
        <AInput label="Deliver address" v-model="state.deliver_address" />
        <AInput label="Deliver city name" v-model="state.deliver_city_name" />
      </div>
    </transition>
  </div>
  <!--/CForm-->
</template>

<script>
export default {
  name: 'PropertyEnvelope',
  props: {
    envelope: {
      type: Object,
      required: true
    },
    validation: {
      type: Array,
      default: () => ['', false, false]
    },
    index: {
      type: Number,
      required: true
    }
  },
  watch: {
    envelope(val) {
      this.state = val
    }
  },
  data() {
    return {
      state: { ...this.envelope }
    }
  },
  methods: {
    onSubmit(e) {
      //console.log('SUBMIT')
    },
    onChange(e) {
      //console.log('CHANGE')
      this.$emit('envelopeChanged', this.state, e)
    },
    isValid(field) {
      if (!this.validation[1]) return true
      return this.validation[1](this.validation[0] + field)
    },
    getErrors(field) {
      if (!this.validation[2]) return []
      return this.validation[2](this.validation[0] + field)
    }
  }
}
</script>
