<template>
  <!--CForm @submit.prevent="onSubmit" @change="onChange"-->
  <div @change="onChange">
    <div v-for="(child, index) of providers[provider.model].__children" :key="index">
      <template>
        <AInput v-if="child.type === 'input'" :name="validation[0] + child.field" :label="child.title" :options="child.options || []" v-model="state[child.field]" :isValid="isValid(child)" :errors="getErrors(child)" />
        <ACheckBox v-if="child.type === 'check'" :name="validation[0] + child.field" :label="child.title" :placeholder="child.label" v-model="state[child.field]" :isValid="isValid(child)" :errors="getErrors(child)" />
        <AMultiSelect v-if="child.type === 'select'" :name="validation[0] + child.field" :label="child.title" :options="child.options" v-model="state[child.field]" :isValid="isValid(child.field)" :errors="getErrors(child.field)" />
        <Label v-if="['input', 'check', 'select'].indexOf(child.type) === -1" :name="validation[0] + child.field" class="text-danger">- {{ $t('Error selecting the provider child element for') + `: ${child.field} (${child.type})` }}</Label>
      </template>
    </div>
  </div>
  <!--/CForm-->
</template>

<script>
export default {
  name: 'PaymentProvider',
  props: {
    provider: {
      type: Object,
      required: true
    },
    lists: {
      type: Object,
      required: true
    },
    validation: {
      type: Array,
      default: () => ['', false, false]
    }
  },
  watch: {
    provider(val) {
      //this.state = val
      this.parseData()
    }
  },
  data() {
    return {
      state: { ...this.provider.data },
      providers: {
        pse_commerce: {
          __children: [
            { field: 'status_id', title: 'Estado', type: 'check', default: false, label: 'Permite activar o inactivar el comercio' },
            { field: 'bank_account_id', title: 'Banco / Cuenta', type: 'select', options: this.lists.bank_accounts, validate: true },
            { field: 'commerce_id', title: 'ID tienda', type: 'input', validate: true },
            { field: 't_route', title: 'T. ruta', type: 'input', validate: true },
            { field: 'ws_password', title: 'Clave webService', type: 'input', validate: true },
            { field: 'service_code', title: 'Código servicio', type: 'input', validate: true },
            { field: 'ws_verification_user', title: 'Usuario verificación', type: 'input', validate: true },
            { field: 'ws_verification_password', title: 'Clave verificación', type: 'input', validate: true }, // TODO: password ?
            { field: 'cross_reference', title: 'Ref. compuesta?', type: 'check', default: false, label: 'La referencia es la unión de la Referencia 1 con la Referencia 2' },
            { field: 'charge_owner', title: 'Paga propietario?', type: 'check', default: false, label: 'Activa si el valor por pago exitoso lo asume el propietario' }
          ]
        },
        cpv_contracts: {
          __children: [
            { field: 'status_id', title: 'Estado', type: 'check', default: false, label: 'Permite activar o inactivar el comercio' },
            { field: 'bank_account_id', title: 'Banco / Cuenta', type: 'select', options: this.lists.bank_accounts, validate: true },
            { field: 'integration_password', title: 'Clave integración', type: 'input', validate: true },
            { field: 'identifier', title: 'Identificador', type: 'input', validate: true },
            { field: 'references', title: 'Referencias', type: 'input', horizontal: { input: 'col-sm-4' }, validate: true },
            { field: 'cross_reference', title: 'Ref. compuesta?', type: 'check', default: false, label: 'La referencia es la unión de la Referencia 1 con la Referencia 2' },
            { field: 'charge_owner', title: 'Paga propietario?', type: 'check', default: false, label: 'Activa si el valor por pago exitoso lo asume el propietario' }
          ]
        }
      }
    }
  },
  mounted() {
    this.parseData()
  },
  methods: {
    onSubmit() {
      //console.log('SUBMIT')
    },
    onChange(e) {
      //console.log('CHANGE')
      this.$emit('providerChanged', this.state, e)
    },
    isValid(child) {
      if (!child.validate || !this.validation[1]) return true
      return this.validation[1](this.validation[0] + child.field)
    },
    getErrors(child) {
      //console.log(this.validation[0] + `.${child.field}`, this.validation[2]('providers.cpv_contracts.data.bank_account_id'))

      if (!child.validate || !this.validation[2]) return []
      return this.validation[2](this.validation[0] + child.field)
    },
    parseData() {
      const model = this.providers[this.provider.model]
      if (model) model.__children.forEach(child => (this.state[child.field] = child.default !== undefined ? child.default : ''))
      this.$nextTick(() => {
        this.state = { ...this.state, ...this.provider.data }
        this.onChange()
      })
    }
  }
}
</script>
