/* eslint-disable @typescript-eslint/no-explicit-any */

export const generateInvoices = (email: any, self: any, month_id: any, ids?: any) => {
  month_id = month_id || self.property._periods?.REC.month_id
  if (month_id) {
    if (email && email.email) {
      self.$http
        .get(`admin/invoices/${month_id}/generate?email=true` + (email.prefix ? `&prefix=${email.prefix}` : '') + (ids ? `&invoice_id=${ids}` : ''))
        .then((response: any) => {
          console.log(response)
        })
        .catch((error: any) => {
          console.error(error)
        })
        .finally(() => {
          self.resetEventToaster()
        })
    } else {
      self.$http
        .getBlob(`admin/invoices/${month_id}/generate` + (ids ? `?invoice_id=${ids}` : ''))
        .then((response: any) => {
          // TODO:  Create a function
          const fileLink = document.createElement('a')
          fileLink.href = window.URL.createObjectURL(new Blob([response.data]))

          if (ids) month_id += `-(${ids.split(',').length})`
          fileLink.setAttribute('download', `invoices-${self.property.id}-${month_id}.pdf`)
          document.body.appendChild(fileLink)

          fileLink.click()
        })
        .catch((error: any) => {
          console.error(error)
        })
        .finally(() => {
          self.resetEventToaster()
        })
    }
  }
}

export const generateReceipts = (email: any, self: any, month_id: any, ids?: any) => {
  month_id = month_id || self.property._periods?.REC.month_id
  if (month_id) {
    if (email && email.email) {
      self.$http
        .get(`admin/receipts/${month_id}/generate?email=true` + (email.prefix ? `&prefix=${email.prefix}` : '') + (ids ? `&receipt_id=${ids}` : ''))
        .then((response: any) => {
          console.log(response)
        })
        .catch((error: any) => {
          console.error(error)
        })
        .finally(() => {
          self.resetEventToaster()
        })
    } else {
      self.$http
        .getBlob(`admin/receipts/${month_id}/generate` + (ids ? `?receipt_id=${ids}` : ''))
        .then((response: any) => {
          const fileLink = document.createElement('a')
          fileLink.href = window.URL.createObjectURL(new Blob([response.data]))

          if (ids) month_id += `-(${ids.split(',').length})`
          fileLink.setAttribute('download', `receipts-${self.property.id}-${month_id}.pdf`)
          document.body.appendChild(fileLink)

          fileLink.click()
        })
        .catch((error: any) => {
          console.error(error)
        })
        .finally(() => {
          self.resetEventToaster()
        })
    }
  }
}

export const generateEnvelopes = (self: any, month_id: any, ids?: any) => {
  month_id = month_id || self.property._periods?.REC.month_id

  const type = 'preview'

  if (month_id) {
    if (type === 'preview') {
      // TODO: create a function
      self.$http
        .get(`admin/envelopes/${month_id}/${type}`)
        .then((response: any) => {
          window.open(response.data.signed_url, `envelopes-${month_id}.pdf`)
        })
        .catch((error: any) => {
          console.error(error)
        })
        .finally(() => {
          self.resetEventToaster()
        })
    } else {
      self.$http
        .getBlob(`admin/envelopes/${month_id}/generate` + (ids ? `?invoice_id=${ids}` : ''))
        .then((response: any) => {
          const fileLink = document.createElement('a')
          fileLink.href = window.URL.createObjectURL(new Blob([response.data]))

          if (ids) month_id += `-(${ids.split(',').length})`
          fileLink.setAttribute('download', `envelopes-${self.property.id}-${month_id}.pdf`)
          document.body.appendChild(fileLink)

          fileLink.click()
        })
        .catch((error: any) => {
          console.error(error)
        })
        .finally(() => {
          self.resetEventToaster()
        })
    }
  }
}
